export default [
  {
    path: '/e/:id',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Übersicht',
        path: 'start',
        props: true,
        component: () => import('../content/start')
      },
      {
        name: 'Tabellen',
        path: 'tables',
        props: true,
        component: () => import('../content/tables')
      },
      {
        name: 'Ranglisten',
        path: 'ranking',
        props: true,
        component: () => import('../content/ranking')
      },
      {
        name: 'Mannschaftsansicht',
        path: 'team/:team',
        props: true,
        component: () => import('../content/mannschaft')
      },
      {
        name: 'Admin > Saison bearbeiten',
        path: 'admin/season',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/season')
      },
      {
        name: 'Admin > Begegnungen bearbeiten',
        path: 'admin/events',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/events')
      },
      {
        name: 'Admin > Verantwortliche bearbeiten',
        path: 'admin/verantwortliche',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/personenverantwortliche')
      },
      {
        name: 'Admin > Personen mit Gymnet abgleichen',
        path: 'admin/gymnet',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/personengymnet')
      },
      {
        name: 'Admin > DTL-Turner freigeben',
        path: 'admin/dtlathletes',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/dtlathletes.vue')
      },
      {
        name: 'Admin > Druckfunktionen',
        path: 'admin/print',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/print')
      },
      {
        name: 'Admin > News bearbeiten',
        path: 'admin/news',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/news')
      },
      {
        name: 'Admin > Statistiken',
        path: 'admin/stats',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/stats')
      },
      {
        name: 'Admin > Kampfrichterplanung',
        path: 'admin/judgeplan',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/judgeplan')
      },
      {
        name: 'Mannschaft > Begegnungen bearbeiten',
        path: 'team/:team/events',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/events')
      },
      {
        name: 'Mannschaft > Turnermeldungen anzeigen',
        path: 'team/:team/turner',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/turner')
      },
      {
        name: 'Mannschaft > Kommunikation',
        path: 'team/:team/communication',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/communication')
      },
      {
        name: 'Mannschaft > Kampfrichtermeldung',
        path: 'team/:team/judges',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/judges')
      }
    ]
  },
  {
    path: '/input/:ids',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Liveeingabe',
        path: '',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/input.vue')
      }
    ]
  },
  {
    path: '/view/:ids',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Liveanzeige',
        path: '',
        props: true,
        component: () => import('../content/view')
      }
    ]
  }
]
