export default [
  {
    path: '/e/:id',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Tabellen',
        path: 'start',
        props: true,
        component: () => import('../content/tables')
      },
      {
        name: 'Wettkampfeingabe',
        path: 'wkinput',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/wkinput')
      },
      {
        name: 'Admin > Landesturnfest bearbeiten',
        path: 'admin/edit',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/edit')
      },
      {
        name: 'Admin > Teamchallenges bearbeiten',
        path: 'admin/tcs',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/tcs')
      }
    ]
  },
  {
    path: '/input/:id',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Liveeingabe',
        path: '',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/input')
      }
    ]
  }
]
