export default [
  {
    path: '/e/:id',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Übersicht',
        path: 'start',
        props: true,
        component: () => import('../content/start.vue')
      },
      {
        name: 'Tabellen',
        path: 'tables',
        props: true,
        component: () => import('../content/tables.vue')
      },
      {
        name: 'Ranglisten',
        path: 'ranking',
        props: true,
        component: () => import('../content/ranking')
      },
      {
        name: 'Admin > Saison bearbeiten',
        path: 'admin/season',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/season')
      },
      {
        name: 'Admin > Begegnungen bearbeiten',
        path: 'admin/events',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/events')
      },
      {
        name: 'Admin > News bearbeiten',
        path: 'admin/news',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/news')
      },
      {
        name: 'Admin > Verantwortliche bearbeiten',
        path: 'admin/verantwortliche',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/personenverantwortliche')
      },
      {
        name: 'Admin > Personen mit Gymnet abgleichen',
        path: 'admin/gymnet',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/personengymnet')
      },
      {
        name: 'Admin > DTL-Turner freigeben',
        path: 'admin/dtlathletes',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/dtlathletes')
      },
      {
        name: 'Admin > Druckfunktionen',
        path: 'admin/print',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/admin/print')
      },
      {
        name: 'Wettkampfeingabe',
        path: 'wkinput',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/wkinput')
      },
      {
        name: 'Mannschaft > Begegnungen bearbeiten',
        path: 'team/:team/events',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/events')
      },
      {
        name: 'Mannschaft > Turnermeldungen anzeigen',
        path: 'team/:team/turner',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/team/turner')
      }
    ]
  },
  {
    path: '/input/:id',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Liveeingabe',
        path: '',
        meta: {
          auth: true
        },
        props: true,
        component: () => import('../content/input')
      }
    ]
  },
  {
    path: '/view/:ids',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Wettkampfanzeige',
        path: '',
        props: true,
        component: () => import('../content/view.vue')
      }
    ]
  }
]
