<template>
  <component
    :is="component"
    v-bind="props"
  />
</template>

<script>
import gql from 'graphql-tag'
import StbM2021Router from './stb_m_2021/plugins/router'
import StbW2023Router from './stb_w_2023/plugins/router'
import StbLtf2024Router from './stb_ltf_2024/plugins/router'

export default {
  name: 'EventRouter',

  props: {
    path: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    p1: {
      type: String
    },
    p2: {
      type: String
    },
    p3: {
      type: String
    },
    p4: {
      type: String
    },
    p5: {
      type: String
    },
    p6: {
      type: String
    },
    p7: {
      type: String
    },
    p8: {
      type: String
    },
    p9: {
      type: String
    },
    p10: {
      type: String
    }
  },

  data: () => ({
    Event: {}
  }),

  computed: {
    target () {
      let f = false
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce((acc, curr) => {
        if (!this[`p${curr}`]) f = true
        if (!f) {
          acc += `/${this[`p${curr}`]}`
        }
        return acc
      }, `/${this.path}/${this.id}`)
    },
    data () {
      switch (this.Event?.type) {
        case 'stb_m_2021':
        case 'stb_m_2021_wk':
          return this.findComponent(StbM2021Router)
        case 'stb_w_2023':
        case 'stb_w_2023_wk':
          return this.findComponent(StbW2023Router)
        case 'stb_ltf_2024':
        case 'stb_ltf_2024_tc':
          return this.findComponent(StbLtf2024Router)
        default: return null
      }
    },
    component () {
      return this.data?.component
    },
    props () {
      return this.data?.path?.match(/\/[^/]*/g)?.map(p => p.replace(/\/:(.*)/, '$1'))?.reduce((acc, curr, i) => {
        if (i === 0) return acc
        if (i === 1) {
          acc[curr] = this.id
        } else {
          const index = 'p' + (i - 1)
          if (curr[0] !== '/') acc[curr] = this[index]
        }
        return acc
      }, {}) || {}
    },
    isLogin () {
      return this.$store.getters.isLogin
    },
    firstId () {
      return this.id.indexOf(',') === -1 ? this.id : this.id.split(',')[0]
    }
  },

  methods: {
    findComponent (r) {
      const paths = r.reduce((acc, curr) => {
        if (!curr.children?.find(c => c.path === '')) acc.push(curr)
        acc.push(...(curr.children || []).map(c => ({
          ...c,
          path: `${curr.path}${c.path !== '' ? `/${c.path}` : ''}`
        })))
        return acc
      }, []).map(p => ({
        ...p,
        regex: '^' + p.path.replaceAll(/\/:[^/]*/g, '/[^/]*') + '$'
      }))

      const c = paths.find(p => {
        return this.target.match(new RegExp(p.regex))
      })

      if (!!c?.meta?.auth && !this.isLogin) {
        this.$router.replace({
          path: '/login',
          query: { origin: this.target }
        })
        return null
      } else {
        this.$store.commit('SET_TITLE', c?.name)
        return c
      }
    }
  },

  apollo: {
    Event: {
      query: gql` query($id: UUID!) {
        Event(id: $id) {
          _id
          type
        }}`,
      variables () {
        return {
          id: this.firstId
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
